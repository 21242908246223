import {Component} from "react";
import { Button, Modal, Form, InputGroup, Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags, faTag, faPlus, faTrash, faClose } from "@fortawesome/free-solid-svg-icons";
import { getApiPath } from "./Utils";

class TagManager extends Component 
{
    constructor(props) {
        super(props);


        this.state = {
            showModal: false
        }
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({ showModal: false})
    }

    addTag = () => {
        var apiPath = getApiPath("create_tag", localStorage.getItem("apiKey"), this.state.newTag)

        fetch(apiPath)
        .then( (response) => {
            if(response.ok)
            {
                this.props.signalTagsEdited()
                this.setState({
                    newTag: ""
                })
            }
            else{
                alert("error adding tag")
            }
        })
    }

    addTagOnChange = (e) => {
        this.setState({
            newTag: e.target.value
        })
    }

    delTag = (tag) => {
        var apiPath = getApiPath("delete_tag", localStorage.getItem("apiKey"), tag)

        fetch(apiPath)
        .then( (response) => {
            if(response.ok){
                this.props.signalTagsEdited();
            }
            else{
                alert("Error deleting tag")
            }
        })

    }

    render() {
        return (
            <span className={this.props.className} >
                <Button className='btn-secondary tag-manager-open-btn' onClick={this.showModal}><FontAwesomeIcon icon={faTags } /> Manage Tags</Button>
                <Modal onHide={this.closeModal} show={this.state.showModal} onEscapeKeyDown={this.closeModal} >
                <Modal.Header >
                        <Modal.Title>Manage Tags</Modal.Title>    
                    </Modal.Header>

                    <Modal.Body>                       
                        <Form>
                            <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faTag} /></InputGroup.Text>
                                <Form.Control type="text" placeholder="Tag Name" value={this.state.newTag} onChange={this.addTagOnChange}></Form.Control>
                                <Button onClick={this.addTag}><FontAwesomeIcon icon={faPlus} /> Add Tag</Button>
                            </InputGroup>
                            <Container className="mt-3">
                            {
                                this.props.tags.map( (tag, ix) => {
                                    return (
                                        <div className="row mt-1" key={`div-${tag}`} >                                            
                                            <Button className="btn-danger" key={`button-{$tag}`} onClick={ () => { this.delTag(tag)} }><FontAwesomeIcon icon={faTrash} /> {tag}</Button>
                                        </div>
                                    )
                                })
                            }
                            </Container>                            
                        </Form>

                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button className='btn btn-secondary' onClick={this.closeModal}><FontAwesomeIcon icon={faClose}/> Close</Button>
                    </Modal.Footer>
                </Modal>
            </span>
        )
    }
}

export default TagManager;