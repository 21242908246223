import { Component } from "react";


class Cannon extends Component
{   

    constructor(props){
        super(props);

        this.state = {
            content: null
        }
    }

    componentDidMount = () => {
       
    }

    render() {
        return (
           "coming soon"
        )
    }
}

export default Cannon;