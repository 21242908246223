import {Component } from "react";
import {Container, Button} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCopy, faFileImport, faBroom, faLink, faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons'

import {getApiPath} from "./Utils.js";

import Dropzone from 'react-dropzone';

class Home extends Component
{
    constructor(props){
        super(props);

        const searchParams = new URLSearchParams(window.location.search);        
        const apikey = searchParams.get("apikey");        
        if (apikey != null )
            this.saveApiKeyToLocalStorage(apikey)        

        this.state = 
        {
            resultUrl: "result urls appear here",
            apiKey: ( localStorage.getItem("apiKey") !== null ? localStorage.getItem("apiKey") : "" ),
            url: "",
            working: false
        }
    }

    saveApiKeyToLocalStorage = (apiKey) => {
        localStorage.setItem("apiKey", apiKey);
        console.log("set localstorage apikey to " + apiKey)
    }

    

    clearUrlField = () => {
        this.setState({ url: ""})
    }

    urlFieldChange = (e) => {
        this.setState({ url: e.target.value })
    }

    rewriteButtonClick = (e) => {
        const apiPath = getApiPath("preview", this.state.apiKey, this.state.url)        
        this.doFetch(apiPath);
    }

    cacheButtonClick = (e) => {
        const apiPath = getApiPath("cache", this.state.apiKey, this.state.url)
        this.doFetch(apiPath);
    }

    proxyButtonClick = (e) => {
        const apiPath = getApiPath("proxy", this.state.apiKey, this.state.url)        
        this.setState({ resultUrl: apiPath});
    }

    handleCopy = () => {            
            if ( navigator.clipboard)
                navigator.clipboard.writeText(this.state.resultUrl);
            else 
                alert("cannot write to clipboard on this device/security context");            
        }
    
    doFetch = (apiUrl) => {

        this.setState({ working: true} , () => {
            fetch(apiUrl)
            .then(response => response.text())
            .then(data => {
                this.setState({ resultUrl: data, working: false});
            })
        })
    }

    keyChange = (e) => {
        this.setState({ apiKey: e.target.value })
   }


    doFileUpload = (file) =>     {
        const formData = new FormData();
        formData.append("file",file, file.name)
        console.dir(formData);
        const url = getApiPath("upload", this.state.apiKey, "");   
        // console.log(url);
        const fetchOptions = 
        {
            method: "post",
            body: formData,
            // headers: {
            //     "Content-Type": "multipart/form-data"
            // }

        }
        console.dir(fetchOptions);

        this.setState( {
            working: true
        }, () => {
        

        fetch(url, fetchOptions)
        .then(response => response.text())
        .then(data => {
                this.setState({
                    resultUrl: data,
                    working: false
                })
            })
        .catch(error => {
            if (typeof error.json === "function") {
                error.json().then(jsonError => {
                    alert("Json error from API");
                    alert(jsonError);
                }).catch(genericError => {
                    alert("Generic error from API");
                    alert(error.statusText);
                });
            } else {
                console.log("Fetch error");
                console.log(error);
            }
        })
        });
    }
  
    onDropAccepted = (files) => {        
        var file = files[0]; //only allowing one at a time in maxFiles
        
        this.doFileUpload(file);
        
        
    }

    onDropRejected = (files) => {        
        alert("Unacceptable file type or file too large.");
    }

    render(){

        const acceptableFiles = {
            "image/png" : [],
            "image/gif" : [],
            "image/jpeg" : [],
            "image/webp" : [],
            "video/mp4" : [],
            "video/quicktime" : []
        }

        return (
            <Container>
                <div className="container-fluid mt-4">
                    <input type="text" className="w-100" id="apikey" placeholder="API Key" value={this.state.apiKey} onChange={this.keyChange }/>
                </div>

                <div className="container-fluid mt-2">
                    <input type="text" className="w-100" placeholder="URL" id="url" value={this.state.url} onChange={this.urlFieldChange} />        
                </div>
            
                <div className="container-fluid w-100 mt-2  sp-button-container ">
                    <div className=' mt-1'><Button id="rewriteButton" onClick={this.rewriteButtonClick}><FontAwesomeIcon icon={faLink} /> Rewrite</Button></div>
                    <div className=" mt-1"><Button className="ml-1" id="cacheButton" onClick={this.cacheButtonClick}><FontAwesomeIcon icon={faFileImport} /> Cache</Button></div>
                    <div className=" mt-1"><Button id="proxyButton" onClick={this.proxyButtonClick}><FontAwesomeIcon icon={faArrowsLeftRight} /> Proxy</Button></div>
                    <div className=' mt-1'><Button className="btn btn-secondary" onClick={this.clearUrlField} id="clearUrlFieldButton"><FontAwesomeIcon icon={faBroom} /> Clear URL Field</Button></div>
                </div>

                <div className="container-fluid w-100 mt-2">            
                    <form id="uploadForm" method="post" encType="multipart/form-data">
                    <Dropzone maxFiles={1}   maxSize={100000000}                 
                        accept={acceptableFiles}
                        onDropAccepted={ this.onDropAccepted}
                        onDropRejected={this.onDropRejected}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p className='dropzone-box'> 
                                Drag and Drop a file here, or click to browse. 
                                Only certain file types accepted.
                                Maximum size 100MB.
                                </p>
                            </div>
                            </section>
                        )}
                        </Dropzone>
                    </form>
                </div>

                <div id="resultDiv" className="container-fluid w-100 mt-2    ">
                    <div>
                        <Button id="copyButton" onClick={this.handleCopy}><FontAwesomeIcon icon={faCopy} /> Copy</Button>
                    </div>
                    <div className='mt-1' id="resultUrl">{this.state.resultUrl}</div>            
                </div>
                { this.state.working === false ? "" : (
                    <div className="overlay">
                        <img src="/images/poop-32x32.png" alt="poop icon" width='100' height='100' className="fa-spin"></img>
                    </div>
                ) }
            </Container>
        )
    }
}

export default Home