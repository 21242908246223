import { Component } from "react";
import  ReactMarkdown  from 'react-markdown';
import  remarkGfm  from 'remark-gfm'
import { Container } from "react-bootstrap";

class AppsClients extends Component
{   

    constructor(props){
        super(props);

        this.state = {
            content: null
        }
    }

    componentDidMount = () => {
        if (this.state.content === null)
        {
            fetch("/markdown/apps.md")
            .then(response => response.text())
            .then( (data ) => {
                this.setState({ content: data})
            })
                
        
        }
    }

    render() {
        return (
            <Container>
                <ReactMarkdown remarkPlugins={remarkGfm} >
                    {this.state.content}
                </ReactMarkdown>
            </Container>
        )
    }
}

export default AppsClients;