import React, {Component} from "react";
// import {Link ,   }   from 'react-router-dom';

import {Navbar, Nav, Container} from 'react-bootstrap';

class AppHeader extends Component 
{

    render() {

        return (
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky="top">
                <Container>
                    <Navbar.Brand>
                        <img src="/images/poop-32x32.png" alt="poop icon" width='20' height='20'></img>
                        <span> Shitpost</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <Nav.Link className='nav-link' href="/">Home</Nav.Link>                            
                            
                            <Nav.Link className='nav-link' href='/stash'>Stash</Nav.Link>
                            {/* <Nav.Link className='nav-link' href="/settings">Settings</Nav.Link> */}
                            {/* <Nav.Link className='nav-link' href="/cannon">Shit Cannon</Nav.Link> */}
                            <Nav.Link className='nav-link' href="/apps">Apps & Clients</Nav.Link>
                            <Nav.Link className='nav-link' href="/shitbot">Shitbot</Nav.Link>
                            <Nav.Link className='nav-link' href="/about">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}


export default AppHeader
