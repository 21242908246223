

function getApiBase () {

  var domain = window.location.host.replace("www-dev.", "");            
  domain = domain.replace("www.", "");
  
  const apiHost = window.location.host.indexOf("dev") > 0 ? "api-dev" : "api";            
  var apipath = window.location.protocol+ "//" + apiHost + "." + domain;

  var override = localStorage.getItem("apiOverride")

  if (override !== null)  
      apipath = override;
  
  return apipath;
}

function getApiPath (mode, apikey, v)
{
    var domain = window.location.host.replace("www-dev.", "");            
    domain = domain.replace("www.", "");
    
    const apiHost = window.location.host.indexOf("dev") > 0 ? "api-dev" : "api";            
    var apipath = window.location.protocol+ "//" + apiHost + "." + domain + "/" + apikey + "/" + mode;
//////////
    // domain = "api-dev.shitpost.sh"

    var override = localStorage.getItem("apiOverride")

    if (override !== null)
    {
        // apipath = "https://" + domain + "/" + apikey + "/" + mode;
        apipath = override + "/" + apikey + "/" + mode;
        //////////////////
    }
    
    if( v !== "" )
        apipath +="/" + v;

    return apipath;
}

function getTogglePostPath(postid, tag)
{
    var apiPath = getApiPath("post", localStorage.getItem("apiKey"), postid) + "/tag/" + tag;
    return apiPath;
}


/**
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }
  

export {getApiPath, getTogglePostPath, humanFileSize, getApiBase};