import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,  
  Route  ,  
} from "react-router-dom";

import AppHeader from './AppHeader';
import Home from './Home';
import Settings from './Settings';
import Stash from './Stash';
import AppsClients from './AppsClients';
import ApiDocs from './ApiDocs';
import About from './About'
import Cannon from './Cannon';
import Shitbot from './Shitbot';

function App() {
  return (
    <Router>
      <div className="App">
        <AppHeader className="App-header"></AppHeader> 
        <div className="App-body">
          <Routes>
            <Route path="" element={<Home />}></Route>         
            <Route path="/settings" element={<Settings />}></Route>                      
            <Route path="/stash" element={<Stash />}></Route>                      
            <Route path="/apps" element={<AppsClients />}></Route>                      
            <Route path="/api-docs" element={<ApiDocs />}></Route>     
            <Route path="/about" element={<About />}></Route>     
            <Route path="/cannon" element={<Cannon />}></Route>     
            <Route path="/shitbot" element={<Shitbot />}></Route>     
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
