import React, {Component} from "react";
import {  Modal, Button, Image, Form, InputGroup, Row, Col, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToilet, faCopy, faTags, faClose, faSave, faUndo, faPoo, faHardDrive, faFileLines, faSquare, faCheckSquare, faVideo, faImage, faUser, faX, faStopwatch, faFileVideo } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';

import {getApiPath, getTogglePostPath, humanFileSize} from "./Utils.js";



class Post extends Component 
{
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            name: this.props.post.name
        }
    }   
    decimalToMinutesAndSeconds(decimalSeconds) {
        // Convert the decimal seconds to minutes and seconds
        const totalSeconds = decimalSeconds % 60;
        const totalMinutes = (decimalSeconds - totalSeconds) / 60;
    
        // Format the minutes and seconds as a string
        const minutes = Math.floor(totalMinutes).toString().padStart(2, '0');
        const seconds = Math.floor(totalSeconds).toString().padStart(2, '0');
    
        // Return the formatted string
        return `${minutes}:${seconds}`;
    }

    showModal = () => {
        // console.dir(this.props.post);
        this.setState({ showModal: true })
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    copy = (e) => {
       

        if ( navigator.clipboard)
            navigator.clipboard.writeText(this.props.post.objectUrl);
        else 
            alert("cannot write to clipboard on this device/security context");     

        this.closeModal();
    }

   
    flush = () => {
        var apiPath = getApiPath("delete_post", localStorage.getItem("apiKey"), this.props.post._id  )
        
        fetch(apiPath, { method: "POST"} )
        .then( (response) => {
            if (response.ok) {
                this.closeModal();
                this.props.signalFlush(this.props.post);
            }
            else
            {
                alert("Some error occured in ")
            }
        });

    }

    toggleTag = (tag) => {
        var path = getTogglePostPath(this.props.post._id, tag);

        fetch(path)
        .then(response => {
            if(response.ok)
            {
                this.props.signalTagToggle();
            }
            else{
                alert("Error toggling tag")
            }
        })
    }

    nameOnChange = (e) => {
        this.setState({
            name: e.target.value
        });
    }

    savePost = () => {
        var apiUrl = getApiPath("post", localStorage.getItem("apiKey"), this.props.post._id)

        fetch(apiUrl,
            {
                method: "post",
                headers: {
                    "Content-Type" : "application/json"
                },
                body: JSON.stringify(
                    {
                        name: this.state.name,
                        expires: null
                    })
            })
            .then( (response) => {
                if (response.ok){
                    this.props.signalPostSaved();
                }
                else{
                    alert("Error saving post")
                }
            })

    }

    resetNameEdits = () => {
        this.setState({ name: this.props.post.name })
    }

    handleKeyPress = (e) => {
        if (e.key === "Enter")
        {
            this.savePost();
        }
    }

    copyIconButtonClick = (e) => {        
        e.stopPropagation(); //cancel parent component's click event
        this.copy();
    }

    reOCR = () => {

        var apiUrl = getApiPath("ocr", localStorage.getItem("apiKey"), this.props.post._id)

        fetch(apiUrl)
        .then( (response) => {
            if(response.ok){
                this.props.signalPostSaved()
            }
            else{
                alert("error processing request\n" + response.text())
            }
        })
        

    }

    render() {



        return (            
            <span className="post"   >                                
                <span onClick={this.showModal} >
                    {
                        
                     <img className="post-img post-img-thumb" alt="post " loading="lazy" src={this.props.post.thumb} ></img>
                        
                    }
                   

                    <div className='post-icons'>                    
                        <span className='ilb'>
                            <ButtonGroup>
                                { this.props.post.expires !== null ? ( <Button title="Eligible for Courtesy Flush" variant="outline-secondary"><FontAwesomeIcon icon={faPoo } /></Button> ) : "" }  
                                

                                
                                    
                                 <Button title={this.props.post.content_type} variant="outline-secondary" >
                                    <FontAwesomeIcon 
                                        icon={this.props.post.content_type.includes("video") ? faVideo : faImage }  />
                                </Button>
                                

                                <Button variant="outline-secondary" title="Tags on post">
                                    <FontAwesomeIcon icon={faTags}/>&nbsp;{this.props.post.tags.length}                                
                                </Button>
                                <Button variant="outline-secondary" title="Copy URL to Item" onClick={this.copyIconButtonClick} >
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </ButtonGroup>                                         
                        </span>
                    </div>
                </span>                

                <Modal 
                    show={this.state.showModal}  
                    onHide={this.closeModal}
                    size="xl"
                    fullscreen="lg-down"
                    onEscapeKeyDown={this.closeModal} >
                    <Modal.Header >
                        <Modal.Title className="post-modal-title">
                        <InputGroup className='post-input-group'>
                                <InputGroup.Text>Name</InputGroup.Text>
                                <Form.Control
                                    type="text" 
                                    className='post-img-name-input'
                                    onChange={this.nameOnChange}
                                    onKeyDown={this.handleKeyPress}
                                    value={this.state.name}
                                />
                                <Button className='btn-primary'><FontAwesomeIcon icon={faSave} onClick={this.savePost} title='Save' /></Button>
                                <Button className='btn-secondary' onClick={this.resetNameEdits}><FontAwesomeIcon icon={faUndo} title='Undo'/></Button>
                            </InputGroup>                         
                        </Modal.Title>    
                    </Modal.Header>

                    <Modal.Body>
                        <Form>                        
                        <Row xs={1} sm={1} lg={1} xl={2}>
                            <Col>
                            
                            
                                {
                                    /// for whatever reason the html video tag wants type=video/mp4 for video/quicktime content.
                                    this.props.post.content_type.includes("video") ? 
                                    ( 
                                        <video className="stash-modal-video" 
                                            type= { this.props.post.content_type === "video/quicktime" ? "video/mp4" : this.props.post.content_type }
                                        autoPlay controls muted  name={this.props.post._id}>
                                            <source src={this.props.post.objectUrl}  />
                                        </video>
                                    ) : 
                                    (
                                        <Image loading="lazy" fluid className="post-img-modal" alt="post " src={this.props.post.objectUrl} ></Image>
                                    )
                                }

                                

                            </Col>
                            <Col>
                                <div className='mt-2'>                                    
                                    {
                                        this.props.allTags.map( (tag, ix ) =>{
                                            return (
                                                <Button className='post-img-modal-tag-btn' key={`check-${tag}`} 
                                                    variant={ this.props.post.tags.includes(tag) ? "info" : "secondary" }
                                                    id={ix}
                                                    label={tag} 
                                                    name={tag}                                                      
                                                    onClick={ () => { this.toggleTag(tag)} } ><FontAwesomeIcon icon={ this.props.post.tags.includes(tag) ? faCheckSquare : faSquare } /> {tag}</Button>
                                            )
                                        })
                                    }
                                </div>
                                
                                <div className='mt-2'>
                                    <span className="post-detail-segment">                                        
                                        <FontAwesomeIcon icon={faFileLines} className='post-detail-icon-col' onClick={this.reOCR} />                                        
                                        <span className='post-detail-segment-ocr'>
                                            { this.props.post.ocr_text !== undefined ? this.props.post.ocr_text : "n/a" }
                                        </span>
                                    </span>
                                </div>

                                <div className='mt-2'>
                                    <span className='post-detail-segment' title="Courtesy Flush" >
                                        <FontAwesomeIcon icon={faPoo} className='post-detail-icon-col' /> 
                                        {
                                            this.props.post.expires !== null ? 
                                            ( 
                                                <Moment tz="Etc/UTC" format="HH:mm:ss" withTitle durationFromNow date={this.props.post.expires['$date'] } />
                                            ) : "will not be courtesy flushed"
                                        }
                                    </span>                                
                                
                                    <span title="Total size for item and any thumbnails/smaller sizes"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={faHardDrive} className='post-detail-icon-col' /> {  this.props.post.total_size !== undefined ?  humanFileSize(this.props.post.total_size, false, 1) : "n/a" }
                                    </span>

                                    <span title="Content_Type"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={this.props.post.content_type.includes("video") ? faVideo : faImage } className='post-detail-icon-col' /> { this.props.post.content_type }
                                    </span>

                                    <span title="Dimensions"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={faX } className='post-detail-icon-col' /> {this.props.post.width !== undefined ? this.props.post.width : "tbd "} x {this.props.post.height !== undefined ? this.props.post.height : "tbd "  }
                                    </span>

                                    {
                                        this.props.post.content_type.includes("video") ? ( <span title="Length/Duration"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={faStopwatch } className='post-detail-icon-col' /> { this.decimalToMinutesAndSeconds(this.props.post.length) }
                                    </span>) : ""
                                    }

                                    {
                                        this.props.post.content_type.includes("video") ? ( <span title="Video Codec"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={faFileVideo } className='post-detail-icon-col' /> { this.props.post.video_codec }
                                    </span>) : ""
                                    }
                                    
                                    <span title="Owner"  className='post-detail-segment' >
                                        <FontAwesomeIcon icon={faUser} className='post-detail-icon-col' /> { this.props.post.owner }
                                    </span>

                                    

                                </div>
                            </Col>                            
                        </Row  >
                        
                        </Form>
                        
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button className='btn btn-danger' onClick={this.flush} > <FontAwesomeIcon icon={faToilet} /> Flush</Button>
                        <Button className='btn btn-primary' onClick={this.copy} > <FontAwesomeIcon icon={faCopy} /> Copy</Button>
                        <Button className='btn btn-secondary' onClick={this.closeModal} > <FontAwesomeIcon icon={faClose} /> Close</Button>
                    </Modal.Footer>
                    
                </Modal>
            </span>
            
            
        )
    }
}


export default Post
