import  {Component} from "react";
import {Container} from 'react-bootstrap';

class Settings extends Component
{    


    render(){
        return (
        <Container>            
            tbd
        
        </Container>)
    }
}

export default Settings